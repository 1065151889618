import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PreviewCompatibleImage from "../PreviewCompatibleImage"

const LetUsHelpYou = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        frontmatter: { sectionKey: { eq: "sections/let-us-help-you" } }
      ) {
        frontmatter {
          featuredItems {
            title
            description
            linkTo
            linkText
            alt
            img {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const { featuredItems } = data.markdownRemark.frontmatter

  return (
    <div className="container section-gap">
      <div className="row">
        <div className="col-12 mb-5 mb-md-7">
          <h2 className="font-bold text-center">
            Let Us <span className="bg-accent px-2 text-white">Help</span> You
          </h2>
        </div>
        {featuredItems.map(
          ({ title, description, img, linkTo, linkText, alt = "" }, i) => {
            const image = {
              order: "",
            }

            const text = {
              order: "",
              padding: "pl-xl-5",
            }

            let cardMargin = "mb-5 mb-sm-7"
            const imgAlt = !!alt ? alt : title

            // alternate cards logic
            if (i % 2 === 0) {
              image.order = "order-xl-2"
              text.order = "order-xl-1"
              text.padding = "px-xl-4"
            }

            // if last item, remove margin
            if (i === featuredItems.length - 1) {
              cardMargin = ""
            }

            return (
              <div
                className={`col-12 custom-shadow bg-white custom-rounded-lg ${cardMargin}`}
                key={i}
              >
                <div className="row p-4 align-items-center">
                  <PreviewCompatibleImage
                    src={img}
                    className={`col-12 col-xl-6 mb-5 mb-xl-0 ${image.order}`}
                    alt={imgAlt}
                  />

                  <div
                    className={`col-12 col-xl-6 px-0 ${text.padding} ${text.order}`}
                  >
                    <h2 className="mb-4 font-black text-center text-sm-left">
                      {title}
                    </h2>
                    <p className="mb-4 text-center text-sm-left">
                      {description}
                    </p>
                    <div className="d-flex justify-content-center justify-content-sm-start">
                      <Link to={linkTo} className="btn btn-primary">
                        {linkText}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        )}
      </div>
    </div>
  )
}

export default LetUsHelpYou
