import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import PricingTable from "../components/PricingTable"
import FeaturedCarousel from "../components/FeaturedCarousel"
import LetUsHelpYou from "../components/LetUsHelpYou"
import FreeDiscussion from "../components/FreeDiscussion"
import ReviewTestimonials from "../components/ReviewTestimonials"

import MoreInfo from "../components/MoreInfo"
import LegalTeam from "../components/LegalTeam"
import Faq from "../components/Faq"
import HeroSection from "../components/HeroSection"
import { Guide } from "../components/Guide"

import AppDownloadBanner from "../components/AppDownloadBanner"

const IndexPage = ({ data, path }) => {
  const { heroSection } = data.markdownRemark.frontmatter

  return (
    <Layout loadMap={false}>
      <SEO path={path} image="/img/Home-Hero.jpeg" />

      {/* HERO */}
      <HeroSection
        id="home-hero"
        formButtonName="homepage"
        bgImage={heroSection.bgImage}
        subTitle={heroSection.subTitle}
        buttonLinks={heroSection.buttonLinks}
      >
        <h1 className="font-black mb-5">
          <span className="d-block">
            <span>Help when you've lost </span>
          </span>
          <span className="d-block d-md-inline-block">
            <span className="emphasize-text">a loved one </span>
          </span>
        </h1>
      </HeroSection>

      <section className="bg-light">
        <ReviewTestimonials />
      </section>

      {/* Let Us Help You */}
      <section className="bg-light">
        <LetUsHelpYou />
      </section>

      <section className="bg-white">
        <AppDownloadBanner />
      </section>

      <section className="bg-light">
        <FreeDiscussion name="homepage" />
      </section>

      {/* Pricing Table */}
      {/* <section className="bg-white">
        <PricingTable tableName="home">
          <h2 className="text-center font-bold mb-5">
            Fixed <span className="emphasize-text">Pricing</span>{" "}
            <span className="emphasize-text">Affordable</span> Services
          </h2>
          <h3 className="text-center">
            <span className="d-block d-md-inline-block mb-2 mb-md-0 mr-0 mr-md-2">
              Save up to 80% on Legal Fees*.
            </span>
            <span className="d-block d-md-inline-block mb-2 mb-md-0 mr-0 mr-md-2">
              Pay Later options available.
            </span>
            <span className="d-block d-md-inline-block">Lawyer Approved.</span>
          </h3>
        </PricingTable>
      </section> */}

      {/* this displays the guide to the deceased estate step by step */}
      <Guide />
      <section className="bg-light">
        <MoreInfo />
      </section>

      <section className="bg-white">
        <LegalTeam />
      </section>

      {/* Featured Carousel */}
      <section className="bg-light">
        <FeaturedCarousel />
      </section>

      <section className="bg-light">
        <Faq name="home">
          <div className="col-12 mb-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <h2 className="mb-4 font-bold text-center">
                <span className="d-block d-md-inline-block mb-3 mb-md-0 mr-0 mr-md-3">
                  Frequently Asked{" "}
                </span>
                <span className="bg-accent px-2 text-white">Questions</span>
              </h2>
              <p className="font-semibold text-center">
                We’re here to help you navigate through the loss of a loved one
              </p>
            </div>
          </div>
        </Faq>
      </section>
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
      frontmatter {
        heroSection {
          bgImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          subTitle {
            content
          }
          buttonLinks {
            linkText
            linkTo
            linkStyle
            type
          }
        }
      }
    }
  }
`
