import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import PreviewCompatibleImage from "../PreviewCompatibleImage"
import MarkdownToHtml from "../MarkdownToHtml"

const MoreInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        frontmatter: { sectionKey: { eq: "sections/more-info" } }
      ) {
        frontmatter {
          title
          description
          imageAlt
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark

  return (
    <div className="container section-gap">
      <div className="row align-items-center">
        <div className="col-lg-6 mb-4 mb-lg-0">
          <div className="d-flex flex-column">
            <h2 className="mb-4 font-black text-center text-lg-left">
              {frontmatter.title}
            </h2>
            <MarkdownToHtml content={frontmatter.description} />
          </div>
        </div>
        <div className="col-lg-6">
          <PreviewCompatibleImage
            src={frontmatter.image}
            alt={frontmatter.imageAlt}
          />
        </div>
      </div>
    </div>
  )
}

export default MoreInfo
